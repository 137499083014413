import MDinput from '@/components/MDinput';
import { validatorRequire } from '@/utils/validators';
import MultiLanguageInput from '@/components/DSE/MultiLanguageInput';
import Switch from '@/components/DSE/Switch';
import NumericInput from '@/components/DSE/NumericInput';
import ImageInput from '@/components/DSE/ImageInput';

export function getFormDefinition(vue) {
  return {
    type: 'tab',
    saveButtons: [true, false, false], // Page, tab 1, tab 2, ...
    showFormLabels: [true, true], // Tab 1, tab 2, ....
    labels: ['common.tabGeneral', 'common.translations'],
    headerTemplate: (str, form) => `
    <h3>
    ${form.name || ''}
    </h3>
    `,
    groups: [
      {
        // Tab 1
        name: {
          type: MDinput,
          defaultValue: '',
          props: {
            maxlength: 64,
            required: true
          },
          rules: [{ validator: validatorRequire }]
        },
        position: {
          type: NumericInput,
          defaultValue: 0,
          props: {
            type: 'number',
            precision: 0,
            caption: 'common.position'
          }
        },
        image: {
          type: ImageInput,
          props: {
            height: 75,
            caption: 'common.image'
          }
        },
        toGoYN: {
          type: Switch,
          defaultValue: false,
          props: {
            caption: 'common.toGo'
          }
        },
        onlineYN: {
          type: Switch,
          defaultValue: false,
          props: {
            activeText: 'common.yes',
            inactiveText: 'common.no'
          }
        }
      },
      {
        // Tab 2
        displayName: {
          type: MultiLanguageInput,
          props: {
            maxlength: 64,
            caption: 'common.displayName'
          }
        },
        description: {
          type: MultiLanguageInput,
          props: {
            maxlength: 1024,
            type: 'textarea',
            caption: 'common.description'
          }
        },
        shortcut: {
          type: MultiLanguageInput,
          props: {
            maxlength: 255,
            caption: 'common.shortcut'
          }
        }
      }
    ]
  };
}

export default getFormDefinition;

export function createItemFromFormData(vue, form) {
  return form;
}

export function createFormDataFromItem(vue, item) {
  return {
    ...item
  };
}
